export const BREAKPOINTS = {
  PHONE_S: 'phoneS',
  PHONE: 'phone',
  TABLET_LANDSCAPE: 'tabletLandscape',
  TABLET_PORTRAIT: 'tabletPortrait',
  DESKTOP: 'desktop',
  DESKTOP_L: 'desktopL',
};

const { PHONE_S, PHONE, TABLET_LANDSCAPE, TABLET_PORTRAIT, DESKTOP_L, DESKTOP } = BREAKPOINTS;

export const colors = {
  lightShade: '#FFFBF4',
  lightAccent: '#ffefbb',
  brandColor: '#22a7f0',
  darkAccent: '#5C3844',
  darkShade: '#000A0F',
  darkTransparentShade: 'rgba(0, 10, 15, 0.9)',
  warningRed: '#e74c3c',
};

export const fonts = {
  montserratRegular: `MontserratRegular`,
  merriweatherBlack: `MerriweatherBlack`,
  merriweatherBold: `MerriweatherBold`,
  merriweatherRegular: `MerriweatherRegular`,
  merriweatherLight: `MerriweatherLight`,
};

export const breakpoints = {
  [PHONE_S]: '(max-width: 359px)',
  [PHONE]: '(min-width: 360px)',
  [TABLET_PORTRAIT]: '(min-width: 600px)',
  [TABLET_LANDSCAPE]: '(min-width: 900px)',
  [DESKTOP]: '(min-width: 1200px)',
  [DESKTOP_L]: '(min-width: 1800px)',
};

export const sizes = {
  h1: {
    [PHONE_S]: '3rem',
    [PHONE]: '3.5rem',
    [TABLET_PORTRAIT]: '4.4rem',
    [TABLET_LANDSCAPE]: '4.6rem',
    [DESKTOP]: '5rem',
    [DESKTOP_L]: '5.4rem',
  },
  h2: {
    [PHONE_S]: '2.5rem',
    [PHONE]: '3rem',
    [TABLET_PORTRAIT]: '3.4rem',
    [TABLET_LANDSCAPE]: '4.2rem',
    [DESKTOP]: '4.2rem',
    [DESKTOP_L]: '4.2rem',
  },
  h3: {
    [PHONE_S]: '2.1rem',
    [PHONE]: '2.5rem',
    [TABLET_PORTRAIT]: '3.2rem',
    [TABLET_LANDSCAPE]: '3.2rem',
    [DESKTOP]: '3.2rem',
    [DESKTOP_L]: '3.2rem',
  },
  h4: {
    [PHONE_S]: '2.4rem',
    [PHONE]: '2.4rem',
    [TABLET_PORTRAIT]: '2.4rem',
    [TABLET_LANDSCAPE]: '2.4rem',
    [DESKTOP]: '2.4rem',
    [DESKTOP_L]: '2.4rem',
  },
  h5: {
    [PHONE_S]: '1.8rem',
    [PHONE]: '1.8rem',
    [TABLET_PORTRAIT]: '1.8rem',
    [TABLET_LANDSCAPE]: '1.8rem',
    [DESKTOP]: '1.8rem',
    [DESKTOP_L]: '1.8rem',
  },
  h6: {
    [PHONE_S]: '1.4rem',
    [PHONE]: '1.4rem',
    [TABLET_PORTRAIT]: '1.4rem',
    [TABLET_LANDSCAPE]: '1.4rem',
    [DESKTOP]: '1.4rem',
    [DESKTOP_L]: '1.4rem',
  },
  paragraph: {
    [PHONE_S]: '2.4rem',
    [PHONE]: '2.2rem',
    [TABLET_PORTRAIT]: '2.7rem',
    [TABLET_LANDSCAPE]: '1.8rem',
    [DESKTOP]: '1.8rem',
    [DESKTOP_L]: '1.8rem',
  },
  nav: {
    [PHONE_S]: '2rem',
    [PHONE]: '1.8rem',
    [TABLET_PORTRAIT]: '1.4rem',
    [TABLET_LANDSCAPE]: '1.3rem',
    [DESKTOP]: '1.3rem',
    [DESKTOP_L]: '1.3rem',
  },
};

export const animations = {
  fast: '.5s',
  medium: '1s',
  slow: '1.5s',
};
