import styled from 'styled-components';

import { colors, breakpoints, fonts } from '../../styles/variables';

import { H6 } from '../../styles/commonStyles';

export const StyledH6 = styled(H6)`
  margin: 0;
  color: ${colors.lightAccent};
  text-align: left;
  font-family: ${fonts.merriweatherBold};
  text-transform: uppercase;
`;

export const Container = styled.footer`
  display: flex;
  width: 100%;
  max-width: 192rem;
  margin: 0 auto;
  flex-direction: column;
  background-color: ${colors.darkShade};
  justify-content: space-between;
  align-items: center;
  text-align: center;

  @media ${breakpoints.tabletLandscape} {
    text-align: left;
    height: 30rem;
  }
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${colors.darkShade};
  padding: 5rem 2rem;

  @media ${breakpoints.tabletLandscape} {
    padding: 5rem;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LeftColumn = styled.div`
  @media ${breakpoints.tabletLandscape} {
    width: 25%;
  }
`;

export const RightColumn = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${breakpoints.tabletLandscape} {
    flex-direction: row;
  }
`;

export const Social = styled.div`
  text-align: center;
  padding: 2rem 0;

  a {
    padding: 0 0.5rem 0;
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  color: ${colors.lightAccent};
  font-size: 1.2rem;
  width: 100%;
  background-color: ${colors.darkShade};
  padding: 2.5rem 2rem;

  div {
    padding: 2rem 0;
  }
  @media ${breakpoints.tabletLandscape} {
    justify-content: space-between;
    padding: 2.5rem 5rem;
    flex-direction: row;
    align-items: center;

    div {
      padding: 0;
    }
  }
`;

export const Logo = styled.img`
  width: 15rem;
  margin: 0 auto;
  font-size: 1.8rem;

  a {
    color: ${colors.lightAccent};
  }

  @media ${breakpoints.tabletLandscape} {
    margin: 0;
    padding: 0;
  }
`;

export const ListBox = styled.div`
  margin: 4rem 0 0;

  @media ${breakpoints.tabletLandscape} {
    margin: 0 5rem;

    :last-child {
      margin-right: 0;
    }
  }
`;

export const List = styled.ul`
  margin-top: 2rem;
  padding-inline-start: 0;
`;

export const ListItem = styled.li`
  color: ${colors.lightAccent};
  font-size: 1.5rem;
  margin: 1rem 0;

  a {
    text-decoration: none;
    color: ${colors.lightAccent};
    font-size: 1.4rem;
    margin: 1rem 0;

    :hover {
      text-decoration: underline;
    }
  }
`;
