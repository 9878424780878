import Styled from 'styled-components';
import { colors, breakpoints, sizes, fonts } from '../../styles/variables';

// eslint-disable-next-line import/prefer-default-export
export const Item = Styled.li`
  text-transform: uppercase;
  letter-spacing: .5rem;
  padding: 2rem 0;

  a {
    font-size: ${sizes.nav.phoneS};
    color: ${colors.lightAccent};
    font-family: ${fonts.merriweatherBold};
    text-transform: uppercase;

    :hover {
      text-decoration: underline;
    }
  }

  @media ${breakpoints.tabletPortrait} {
    a {
      font-size: ${sizes.nav.tablet};
    }
  }

  @media ${breakpoints.desktop} {
    margin-right: 1.7rem;
    display: ${({ name }) => (name === 'Kontakt' ? 'none' : 'list-item')};

    a {
      font-size: ${sizes.nav.desktop};
    }
  }

  @media ${breakpoints.desktopL} {
    a {
      font-size: ${sizes.nav.desktopL};
    }
  }
`;
