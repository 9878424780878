import styled from 'styled-components';

import { breakpoints, colors, fonts, sizes } from '../../styles/variables';

import Button from '../Button/Button';

export const NavigationPanel = styled.nav`
  position: fixed;
  width: 100%;
  height: ${({ height }) => `${height}px` || '100vh'};
  top: ${({ height }) => `-${height * 1.5}px` || '-150vh'};
  left: 0;
  ${({ isMenuActive }) => isMenuActive && 'transform: translate3D(0, 150%, 0);'}
  background-color: ${colors.darkTransparentShade};
  display:flex;
  justify-content: space-between;
  flex-direction: column;

  @media ${breakpoints.desktop} {
    position: relative;
    top: 0;
    transform: translate3D(0, 0, 0);
    height: auto;
    background-color: transparent;
  }
`;

export const NavigationItems = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-inline-start: 0;

  @media ${breakpoints.desktop} {
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
    padding: 0;
  }
`;

export const NavigationFooter = styled.li`
  position: relative;
  bottom: 0;
  height: 8rem;
  width: 100%;
  padding: 2rem 0;

  @media ${breakpoints.tabletLandscape} {
    display: none;
  }
`;

export const ContactWrapper = styled.div`
  position: relative;
  margin-top: 2rem;

  @media ${breakpoints.tabletLandscape} {
    margin-top: 0;
    margin-left: 2rem;
  }

  @media ${breakpoints.desktop} {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22rem;
    height: 100%;
  }
`;

export const ContactButton = styled(Button)`
  font-family: ${fonts.merriweatherBold};
  text-transform: uppercase;
  font-size: ${sizes.nav.phoneS};
  margin: 2rem 0;
  letter-spacing: 0.5rem;
  display: none;

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.nav.tablet};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.nav.tabletLandscape};
    margin: 0;
  }

  @media ${breakpoints.desktop} {
    display: block;
    font-size: ${sizes.nav.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.nav.desktopL};
  }
`;
