import React from 'react';
import CookieConsent from 'react-cookie-consent';

import { colors } from '../../styles/variables';

const Cookies = () => (
  <CookieConsent
    location="bottom"
    buttonText="Zgadzam się"
    cookieName="AcceptedCookies"
    style={{
      background: `${colors.darkShade}`,
      padding: '0 4rem',
      fontSize: '1.1rem',
      lineHeight: '1.4rem',
    }}
    buttonStyle={{
      color: `${colors.lightAccent}`,
      background: `${colors.darkTransparentShade}`,
      fontSize: '1rem',
      padding: '1rem',
      border: `.2rem solid ${colors.lightAccent}`,
    }}
  >
    Informujemy, że ta strona korzysta z plików cookies. Odwiedzając naszą stronę bez dokonania
    zmian ustawień swojej przeglądarki, wyrażasz zgodę na wykorzystanie przez nas plików cookies w
    celu ułatwienia korzystania z serwisu.
    <a href="/cookies" style={{ color: `${colors.lightShade}`, padding: '0 .5rem' }}>
      Więcej informacji
    </a>
  </CookieConsent>
);

export default Cookies;
