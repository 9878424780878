import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { throttle } from '../../helpers/throttle';
import { breakpoints } from '../../styles/variables';

import Navigation from '../Navigation/Navigation';

import { Container, Logo, StyledHeader, Hamburger } from './Header.style';

const propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const Header = ({ list }) => {
  // This is for SSR - we need to check if window object is available
  const isBrowser = typeof window !== 'undefined';

  const isScrolled = () => isBrowser && window.scrollY > 0;

  const [isMenuActive, setToggleMenu] = useState(false);
  const [fixed, setFixed] = useState(isScrolled());
  const [navHeight, setNavHeight] = useState(0);

  const handleIsScrolled = () => {
    setFixed(isScrolled());
  };

  const throttledHandleIsScrolled = throttle(handleIsScrolled, 100);

  const getInnerHeight = () => {
    if (isBrowser) setNavHeight(window.innerHeight);
  };

  useEffect(() => {
    getInnerHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', getInnerHeight);
    window.addEventListener('scroll', throttledHandleIsScrolled);

    return () => {
      window.removeEventListener('resize', getInnerHeight);
      window.removeEventListener('scroll', throttledHandleIsScrolled);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleMenu = () => {
    if (isBrowser && window.innerWidth < breakpoints.desktop.match(/\d+/)) {
      setToggleMenu(prevIsMenuActive => !prevIsMenuActive);
    }
  };

  return (
    <StyledHeader fixed={fixed}>
      <Container fixed={fixed}>
        <Link to="/" aria-label="Logo Stara Piekarnia">
          <Logo fixed={fixed}>
            <img src="/img/logo00.png" alt="Logo Stara Piekarnia" />
          </Logo>
        </Link>
        <Navigation
          list={list}
          isMenuActive={isMenuActive}
          handleToggleMenu={onToggleMenu}
          heightOnMobile={navHeight}
        />
        <Hamburger isMenuActive={isMenuActive} onClick={onToggleMenu} />
      </Container>
    </StyledHeader>
  );
};

Header.propTypes = propTypes;

export default Header;
