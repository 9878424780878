import React from 'react';
import PropTypes from 'prop-types';

import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Cookies from '../components/Cookies/Cookies';

import GlobalStyles from '../styles/globalStyles';

import SEO from '../components/Seo/Seo';

const propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const Layout = ({ children, pageContext }) => {
  const navigatonList = [
    {
      id: 0,
      name: 'Restauracja',
      url: '/restauracja/',
    },
    {
      id: 1,
      name: 'Apartamenty',
      url: '/apartamenty/',
    },
    {
      id: 2,
      name: 'Wydarzenia',
      url: '/wydarzenia/',
    },
    {
      id: 3,
      name: 'Oferta',
      url: '/oferta/',
    },
    {
      id: 4,
      name: 'Kontakt',
      url: '/kontakt/',
    },
  ];

  const FooterNavigationList = [
    ...navigatonList,
    {
      id: 6,
      name: 'Polityka prywatnosci',
      url: '/polityka-prywatnosci/',
    },
  ];

  const { title } = pageContext;

  return (
    <>
      <SEO title={title} />
      <GlobalStyles />
      <Header list={navigatonList} />
      <main>{children}</main>
      <Footer list={FooterNavigationList} />
      <Cookies />
    </>
  );
};

Layout.propTypes = propTypes;

export default Layout;
