import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { colors } from './variables';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'MerriweatherBlack';
    src: url('/fonts/Merriweather-Black.ttf') format('truetype'),
         url('/fonts/Merriweather-Black.woff2') format('woff2'),
         url('/fonts/Merriweather-Black.woff') format('woff'),
         url('/fonts/Merriweather-Black.svg#merriweatherblack') format('svg');
  }

  @font-face {
    font-family: 'MerriweatherBold';
    src: url('/fonts/Merriweather-Bold.ttf') format('truetype'),
         url('/fonts/Merriweather-Bold.woff2') format('woff2'),
         url('/fonts/Merriweather-Bold.woff') format('woff'),
         url('/fonts/Merriweather-Bold.svg#merriweatherbold') format('svg');
  }

  @font-face {
    font-family: 'MerriweatherRegular';
    src: url('/fonts/Merriweather-Regular.ttf') format('truetype'),
         url('/fonts/Merriweather-Regular.woff2') format('woff2'),
         url('/fonts/Merriweather-Regular.woff') format('woff'),
         url('/fonts/Merriweather-Regular.svg#merriweatherregular') format('svg');
  }

  @font-face {
    font-family: 'MerriweatherLight';
    src: url('/fonts/Merriweather-Light.ttf') format('truetype'),
         url('/fonts/merriweather-Light.woff2') format('woff2'),
         url('/fonts/merriweather-Light.woff') format('woff'),
         url('/fonts/merriweather-Light.svg#merriweatherlight') format('svg');
  }

  @font-face {
    font-family: 'MontserratRegular';
    src: url('/fonts/Montserrat-Regular.ttf') format('truetype'),
         url('/fonts/Montserrat-Regular.woff2') format('woff2'),
         url('/fonts/Montserrat-Regular.woff') format('woff'),
         url('/fonts/Montserrat-Regular.svg#montserratregular') format('svg');
  }

  *, *:before, *:after {
    box-sizing: border-box;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }

  body {
    font-family: 'MontserratRegular';
    font-weight: 400;
    line-height: 1;
    color: ${colors.darkAccent};
    background-color: ${colors.lightShade};
    height: 100%;
  }

  main {
    overflow-x: hidden;
  }

  ul {
    list-style-type: none;
  }

  p {
    font-family: 'MontserratRegular';
  }

  a, button, input, textarea {
    text-decoration: none;

    :focus,:active,:hover {
      outline: 0;
    }
  }
`;

export default GlobalStyle;
