import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Socials from '../Socials/Socials';
import {
  Container,
  StyledH6,
  Top,
  Bottom,
  LeftColumn,
  RightColumn,
  Logo,
  ListBox,
  List,
  ListItem,
} from './Footer.style';

const propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

const Footer = ({ list }) => {
  const isExternal = url => url.match(/(http|https):\/\//);

  return (
    <Container>
      <Top>
        <LeftColumn>
          <Link to="/" aria-label="Logo Stara Piekarnia">
            <Logo src="/img/logo00.png" alt="Logo Stara Piekarnia" />
          </Link>
        </LeftColumn>
        <RightColumn>
          <ListBox>
            <StyledH6>STARA PIEKARNIA</StyledH6>
            <List>
              {list.map(({ id, name, url }) => (
                <ListItem key={id}>
                  {isExternal(url) ? (
                    <a href={url} aria-label={name} target="_blank" rel="noopener noreferrer">
                      {name}
                    </a>
                  ) : (
                    <Link to={url} aria-label={name}>
                      {name}
                    </Link>
                  )}
                </ListItem>
              ))}
            </List>
          </ListBox>
          <ListBox>
            <StyledH6>GODZINY OTWARCIA</StyledH6>
            <List>
              <ListItem>Serdecznie zapraszamy</ListItem>
              <ListItem>do restauracji od godziny 12:30</ListItem>
              <ListItem>do recepcji całodobowo</ListItem>
            </List>
          </ListBox>
          <ListBox>
            <StyledH6>KONTAKT</StyledH6>
            <List>
              <ListItem>Stara Piekarnia</ListItem>
              {/* <ListItem>ul. Reptowska 4</ListItem> */}
              {/* <ListItem>41-908 Bytom Miechowice</ListItem> */}
              <ListItem>
                Telefon: <a href="tel:+48322802200">+48 32 280 22 00</a>
              </ListItem>
              <ListItem>
                Tel.kom.: <a href="tel:+48600023024">+48 600 023 024</a>
              </ListItem>
              <ListItem>
                Manager: <a href="tel:+48783123583">+48 783 123 583</a>
              </ListItem>
              <ListItem>
                <a href="mail to:biuro@starapiekarnia.com">
                  biuro<code>@</code>starapiekarnia.com
                </a>
              </ListItem>
            </List>
          </ListBox>
        </RightColumn>
      </Top>
      <Bottom>
        <Socials />
        Wszelkie prawa zastrzeżone Stara Piekarnia {new Date().getFullYear()}
      </Bottom>
    </Container>
  );
};

Footer.propTypes = propTypes;

export default Footer;
