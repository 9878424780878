// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-typography-js": () => import("./../../../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-templates-apartments-apartments-js": () => import("./../../../src/templates/apartments/apartments.js" /* webpackChunkName: "component---src-templates-apartments-apartments-js" */),
  "component---src-templates-contact-contact-js": () => import("./../../../src/templates/contact/contact.js" /* webpackChunkName: "component---src-templates-contact-contact-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-news-news-js": () => import("./../../../src/templates/news/news.js" /* webpackChunkName: "component---src-templates-news-news-js" */),
  "component---src-templates-offer-offer-js": () => import("./../../../src/templates/offer/offer.js" /* webpackChunkName: "component---src-templates-offer-offer-js" */),
  "component---src-templates-post-post-js": () => import("./../../../src/templates/post/post.js" /* webpackChunkName: "component---src-templates-post-post-js" */),
  "component---src-templates-privacy-policy-privacy-policy-js": () => import("./../../../src/templates/privacyPolicy/privacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-js" */),
  "component---src-templates-pub-pub-js": () => import("./../../../src/templates/pub/pub.js" /* webpackChunkName: "component---src-templates-pub-pub-js" */),
  "component---src-templates-restaurant-restaurant-js": () => import("./../../../src/templates/restaurant/restaurant.js" /* webpackChunkName: "component---src-templates-restaurant-restaurant-js" */),
  "component---src-templates-room-room-js": () => import("./../../../src/templates/room/room.js" /* webpackChunkName: "component---src-templates-room-room-js" */)
}

