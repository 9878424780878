import React from 'react';
import PropTypes from 'prop-types';

import { ButtonWrapper } from './Button.style';

const propTypes = {
  appearance: PropTypes.oneOf(['primary', 'secondary', 'invert']),
  size: PropTypes.oneOf(['small', 'regular', 'big']),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  centered: PropTypes.bool,
  block: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  handleClick: PropTypes.func,
};

const defaultProps = {
  appearance: 'primary',
  size: 'regular',
  disabled: false,
  centered: false,
  block: false,
  handleClick: () => {},
  className: 'Button',
};

const Button = ({
  className,
  children,
  handleClick,
  appearance,
  size,
  disabled,
  block,
  centered,
}) => (
  <ButtonWrapper
    appearance={appearance}
    size={size}
    disabled={disabled}
    block={block}
    centered={centered}
    onClick={handleClick}
    className={className}
  >
    {children}
  </ButtonWrapper>
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
