import Styled from 'styled-components';

import { Menu } from 'styled-icons/boxicons-regular/Menu';
import { breakpoints, colors, animations } from '../../styles/variables';

export const StyledHeader = Styled.header`
  position: fixed;
  z-index: 2;
  width: 100%;
`;

export const Container = Styled.div`
  box-sizing: border-box;
  background-color: transparent;
  max-width: 192rem;
  margin: 0 auto;
  padding: ${({ fixed }) => (fixed ? '1rem 0' : '1rem 2rem')};
  transition: padding 0.5s ease-in-out;
  background: ${({ fixed }) =>
    fixed ? colors.darkShade : 'linear-gradient(#000A0F, rgba(0,0,0,0))'};
  height: ${({ fixed }) => (fixed ? '7rem' : '15rem')};
  transition: background, height ${animations.fast} linear;

  @media ${breakpoints.tabletLandscape} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ fixed }) => (fixed ? '1rem 5rem' : '2rem 5rem')};
  }
`;

export const Logo = Styled.div`
  text-align: center;

  img {
    transition: width 0.5s linear;
    width: ${({ fixed }) => (fixed ? '10rem' : '16rem')};
    z-index: 1;
  }

  @media ${breakpoints.tabletLandscape} {
    margin: 0;
    padding: 0;
  }
`;

export const Hamburger = Styled(Menu)`
  width: 4rem;
  color: ${colors.lightAccent};
  position: fixed;
  right: 1.5rem;
  top: 1.5rem;
  z-index: 1;
  cursor: pointer;

  @media ${breakpoints.desktop} {
    display: none;
  }
`;
