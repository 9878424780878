import styled, { css } from 'styled-components';

import { fonts, colors, breakpoints, sizes } from './variables';

const commonHeadingStyles = css`
  display: inline-block;
  overflow-wrap: break-word;
  letter-spacing: 0.5rem;
  font-family: ${fonts.merriweatherBold};
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  margin: 1rem 0;
  color: ${({ color }) => color || `${colors.lightShade}`};
`;

export const H1 = styled.h1`
  ${commonHeadingStyles}

  font-size: ${sizes.h1.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h1.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h1.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.h1.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h1.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h1.desktopL};
  }
`;

export const H2 = styled.h2`
  ${commonHeadingStyles}

  font-size: ${sizes.h2.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h2.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h2.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.h2.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h2.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h2.desktopL};
  }
`;

export const H3 = styled.h3`
  ${commonHeadingStyles}

  font-size: ${sizes.h3.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h3.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h3.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.h3.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h3.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h3.desktopL};
  }
`;

export const H4 = styled.h4`
  ${commonHeadingStyles}


  font-size: ${sizes.h4.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h4.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h4.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.h4.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h4.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h4.desktopL};
  }
`;

export const H5 = styled.h5`
   ${commonHeadingStyles}

  font-size: ${sizes.h5.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h5.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h5.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.h5.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h5.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h5.desktopL};
  }
`;

export const H6 = styled.h6`
  ${commonHeadingStyles}

  font-size: ${sizes.h6.phoneS};

  @media ${breakpoints.phone} {
    font-size: ${sizes.h6.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.h6.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.h6.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.h6.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.h6.desktopL};
  }
`;

export const Paragraph = styled.p`
  font-size: ${sizes.paragraph.phoneS};
  line-height: 1.5;
  margin: 1rem 0;

  @media ${breakpoints.phone} {
    font-size: ${sizes.paragraph.phone};
  }

  @media ${breakpoints.tabletPortrait} {
    font-size: ${sizes.paragraph.tabletPortrait};
  }

  @media ${breakpoints.tabletLandscape} {
    font-size: ${sizes.paragraph.tabletLandscape};
  }

  @media ${breakpoints.desktop} {
    font-size: ${sizes.paragraph.desktop};
  }

  @media ${breakpoints.desktopL} {
    font-size: ${sizes.paragraph.desktopL};
  }
`;
