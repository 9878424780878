import Styled from 'styled-components';

import { colors } from '../../styles/variables';

export const Social = Styled.div`
  display: flex;
  justify-content: center;
  margin-right: 2rem;

  a {
    margin: 0 0.5rem;
  }
  a:last-child {
    margin: .4rem 0 0 1.3rem;
  }
`;

export const Booking = Styled.img`
  width: 2.7rem;
  color: ${colors.lightAccent};
`;
