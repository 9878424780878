import styled, { css } from 'styled-components';

import { colors, fonts } from '../../styles/variables';

const sizes = {
  small: {
    width: '14rem',
    height: '4rem',
  },
  regular: {
    width: '20rem',
    height: '4.8rem',
  },
  big: {
    width: '30rem',
    height: '4.8rem',
  },
};

const backgroundColors = {
  primary: 'transparent',
  secondary: colors.darkAccent,
  invert: 'transparent',
};

const borderColors = {
  primary: colors.darkAccent,
  secondary: colors.darkAccent,
  invert: colors.lightAccent,
};

const textColors = {
  primary: colors.darkAccent,
  secondary: colors.lightShade,
  invert: colors.lightAccent,
};

export const ButtonStyle = css`
  display: block;
  width: ${({ size = 'regular' }) => sizes[size].width};
  height: ${({ size = 'regular' }) => sizes[size].height};
  line-height: calc(${({ size = 'regular' }) => sizes[size].height} - 0.2rem);
  font-size: 1.6rem;
  font-family: ${fonts.merriweatherBold};
  text-transform: uppercase;
  text-align: center;
  ${({ block = false }) => block && 'width: 100%;'}
  background-color: ${({ appearance = 'primary' }) => backgroundColors[appearance]};
  color: ${({ appearance = 'primary' }) => textColors[appearance]};
  border: 2px solid ${({ appearance = 'primary' }) => borderColors[appearance]};
  ${({ disabled = false }) => disabled && 'pointer-events: none;'}
  ${({ centered = false }) => centered && 'margin: 0 auto;'}
  padding: 0;
  cursor: pointer;
  transition: all 0.1s;

  :hover {
    color: ${({ appearance = 'primary' }) =>
      appearance === 'invert' ? colors.darkAccent : colors.lightShade};;
    background-color: ${({ appearance = 'primary' }) =>
      appearance === 'invert' ? colors.lightAccent : colors.darkAccent};
    ${({ block = false }) =>
      !block && `width: calc(${({ size = sizes.regular }) => sizes[size].width} + 1rem)`};
  }

  :disabled {
    opacity: 0.5;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const ButtonWrapper = styled.button`
  ${ButtonStyle};
`;
