import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import { Item } from './NavigationItem.style';

const propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  handleToggleMenu: PropTypes.func.isRequired,
};

const NavigationItem = ({ name, url, handleToggleMenu }) => {
  const internal = /^\/(?!\/)/.test(url);

  return (
    <Item name={name}>
      {internal ? (
        <Link onClick={handleToggleMenu} aria-label={name} to={url}>
          {name}
        </Link>
      ) : (
        <a
          onClick={handleToggleMenu}
          aria-label={name}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      )}
    </Item>
  );
};

NavigationItem.propTypes = propTypes;

export default NavigationItem;
