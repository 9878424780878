import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import Socials from '../Socials/Socials';
import {
  NavigationPanel,
  NavigationItems,
  ContactButton,
  NavigationFooter,
} from './Navigation.style';

import NavigationItem from './NavigationItem';

const propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isMenuActive: PropTypes.bool.isRequired,
  handleToggleMenu: PropTypes.func.isRequired,
  heightOnMobile: PropTypes.number.isRequired,
};

const Navigation = ({ list, isMenuActive, handleToggleMenu, heightOnMobile }) => (
  <NavigationPanel height={heightOnMobile} isMenuActive={isMenuActive}>
    <NavigationItems>
      {list.map(({ id, name, url }) => (
        <NavigationItem handleToggleMenu={handleToggleMenu} key={id} name={name} url={url} />
      ))}
      <Link onClick={handleToggleMenu} aria-label="Kontakt" to="/kontakt/">
        <ContactButton size="small" appearance="invert">
          KONTAKT
        </ContactButton>
      </Link>
    </NavigationItems>
    <NavigationFooter isMenuActive={isMenuActive}>
      <Socials />
    </NavigationFooter>
  </NavigationPanel>
);

Navigation.propTypes = propTypes;

export default Navigation;
