import React from 'react';

import { Facebook } from 'styled-icons/boxicons-logos/Facebook/';
import { Instagram } from 'styled-icons/boxicons-logos/Instagram/';

import { colors } from '../../styles/variables';

import { Social, Booking } from './Socials.style';

const Socials = () => (
  <Social>
    <a
      href="https://www.facebook.com/starapiekarnia.bytom/"
      aria-label="Facebook"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Facebook size="35" color={colors.lightAccent} />
    </a>
    <a
      href="https://www.instagram.com/starapiekarniabytom/"
      aria-label="Instagram"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Instagram size="35" color={colors.lightAccent} />
    </a>
    <a
      href="https://www.booking.com/hotel/pl/stara-piekarnia.pl.html"
      aria-label="Booking"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Booking alt="ikona bookingu" src="/img/booking_icon.svg" />
    </a>
  </Social>
);

export default Socials;
