// eslint-disable-next-line import/prefer-default-export
export const throttle = (fn, t, scope) => {
  const threshold = t || 250;
  let last;
  let deferTimer;

  return function throttledFn(...args) {
    const context = scope || this;
    const now = +new Date();

    if (last && now < last + threshold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(() => {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
};
